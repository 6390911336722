@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?fhx0we');
    src: url('fonts/icomoon.eot?fhx0we#iefix') format('embedded-opentype'),
        url('fonts/icomoon.ttf?fhx0we') format('truetype'),
        url('fonts/icomoon.woff?fhx0we') format('woff'),
        url('fonts/icomoon.svg?fhx0we#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    position: relative;
    top: 1px;
    display: inline-block;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-1 {
    font-size: 1.5em !important;
}

.icon-2 {
    font-size: 1.8em !important;
}

.icon-9 {
    font-size: 9px !important;
}

.icon-12 {
    font-size: 12px !important;
}

.icon-14 {
    font-size: 14px !important;
}

.icon-16 {
    font-size: 16px !important;
}

.icon-24 {
    font-size: 24px !important;
}

.icon-32 {
    font-size: 32px !important;
}

.icon-40 {
    font-size: 40px !important;
}

.icon-48 {
    font-size: 48px !important;
}

.icon-checkbox:before {
    content: '\e900';
}

.icon-checkbox-empty:before {
    content: '\e901';
}

.icon-clear-filter:before {
    content: '\e902';
    color: #676768;
}

.icon-delete:before {
    content: '\e903';
}

.icon-file-download:before {
    content: '\e904';
}

.icon-file-upload:before {
    content: '\e905';
}

.icon-filter:before {
    content: '\e906';
}

.icon-filter-full:before {
    content: '\e907';
}

.icon-multi-checkbox:before {
    content: '\e908';
}

.icon-multi-checkbox-empty:before {
    content: '\e909';
}

.icon-multi-checkbox-some-outline:before {
    content: '\e90a';
}
